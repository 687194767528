"use client"
import React, {FunctionComponent, useEffect, useState}                       from 'react'
import Layout                                                                from '../components/shared/layout'
import {Button, Card, CardContent, Container, Grid, Link, Theme, Typography} from '@material-ui/core'
import {graphql}                                                             from 'gatsby'
import theme                                                                 from '../theme'
import {makeStyles}                                                          from '@material-ui/core/styles'
import SEO                                                                   from '../components/shared/seo'
import moment                                                                from 'moment'
import PortableText                                                          from '../components/portableText'
import {Slug}                                                                from '@open-law/fab/shared-entities'
import SanityImage                                                           from "gatsby-plugin-sanity-image";


export const query = graphql`
    query {
        eventList: allSanityEvent(sort: {order: DESC, fields: startDateTime}) {
            edges {
                node {
                    title
                    _rawBody(resolveReferences: {maxDepth: 5})
                    publishedAt
                    startDateTime
                    endDateTime
                    registrationButton {
                        buttonText
                        url
                    }
                    _rawExcerpt(resolveReferences: {maxDepth: 5})
                    mainImage {
                        ...ImageData
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`


const useStyles = makeStyles((theme: Theme) => ({
    article: {},
    articleTitle: {},
    headerContainer: {},
    byline: {},
    dateLine: {},
    bodyWrapper: {backgroundColor: '#F5F5F5', minHeight: '800px'},
    bodyContainer: {marginBottom: '1em'},
    blogListItem: {
        fontWeight: 600
    },
    articleListContainer: {},
    pageTitle: {
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '1.5rem'
    },
    cardRoot: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    cardContent: {
        flex: '1 0 auto',
        padding: '1rem 2rem',
        '&:hover': {
            background: '#F5F5F5'
        }
    },
    cardImage: {
        width: '600px',
        height: '350px',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    readMoreBtn: {
        background: '#E5E5E5',
        width: '160px'
    },
    bookNowBtn: {
        background: '#3A3737',
        color: '#FFF',
        width: '240px',
        '&:hover': {
            background: '#3A3730'
        }
    },
    spaceBelow: {
        marginBottom: '1rem'
    }
}))

export interface EventItem {
    title: string
    _rawExcerpt: any
    _rawBody: any
    publishedAt: string
    startDateTime: string
    endDateTime: string
    slug: Slug
    mainImage: any
    registrationButton: {
        url: string
        buttonText: string
    }
    bookNow: {
        url: string
        buttonText: string
    }
}

type EventPageProps = {
    data: {
        eventList: {
            edges: {
                node: EventItem
            }[]
        }
    }
}

const EventsPage: FunctionComponent<EventPageProps> = (props) => {
    const classes = useStyles(theme)
    const [eventList, setEventList] = useState<EventItem[]>([])
    const [pastEventList, setPastEventList] = useState<EventItem[]>([])

    useEffect(() => {

        const events = props.data.eventList.edges

        setEventList(events
            .filter(({node}) => {
                return moment(node.startDateTime).isAfter(moment())
            }).map(({node}) => node)
        )

        setPastEventList(events
            .filter(({node}) => {
                return moment(node.startDateTime).isBefore(moment())
            }).map(({node}) => node)
        )
    }, [props.data.eventList])

    const renderEventList = (eventList: EventItem[]) => (
        <Grid container justify={'center'} spacing={4}>
            {
                eventList && eventList.map((node, idx: number) => {
                                  const eventItem = node
                                  return (
                                      <Grid key={idx} item xs={12}>
                                          <Card className={classes.cardRoot}>
                                              {
                                                  eventItem.mainImage && (
                                                                          <Link href={`/events/${eventItem.slug.current}`} component={'a'}>
                                                                              <SanityImage
                                                                                  style={{objectFit: 'cover', width: 600, height: '100%'}}
                                                                                  {...eventItem.mainImage}
                                                                                  height={800}/>
                                                                          </Link>
                                                                      )}
                                              <section className={classes.cardDetails}>
                                                  <CardContent className={classes.cardContent}>
                                                      <Link href={`/events/${eventItem.slug.current}`} component={'a'}>
                                                          <Typography
                                                              gutterBottom
                                                              variant={'h5'}
                                                              component={'h2'}
                                                              className={classes.blogListItem}
                                                              key={idx}
                                                          >
                                                              {eventItem.title}
                                                          </Typography>
                                                      </Link>
                                                      {
                                                          eventItem && eventItem._rawExcerpt && (
                                                                        <PortableText blocks={eventItem._rawExcerpt}/>
                                                                    )
                                                      }
                                                      <Typography className={classes.spaceBelow} variant={'subtitle1'}
                                                                  component={'p'}>
                                                          <strong>Event
                                                              Date: </strong> {moment(eventItem.startDateTime).format('D MMMM YYYY')}<br/>
                                                          <strong>Time: </strong>{moment(eventItem.startDateTime).format('HH:mm')}
                                                      </Typography>
                                                      <Grid container justify={'space-between'}>
                                                          <Grid item>
                                                              <Button className={classes.readMoreBtn} size={'large'} component={'a'}
                                                                      href={`/events/${eventItem.slug.current}`}>
                                                                  Read more
                                                              </Button>
                                                          </Grid>
                                                          <Grid item>
                                                              {eventItem.registrationButton && (
                                                                  <Button href={eventItem.registrationButton.url} target={'_blank'}
                                                                          className={classes.bookNowBtn} size={'large'}
                                                                          component={'a'}>
                                                                      {eventItem.registrationButton.buttonText}
                                                                  </Button>
                                                              )}
                                                          </Grid>
                                                      </Grid>
                                                  </CardContent>
                                              </section>
                                          </Card>
                                      </Grid>
                                  )
                              }
                          )
            }
        </Grid>
    )

    return (
        <Layout backgroundColor={'#F5F5F5'} helmetData={{
            pageTitle: 'Events + Seminars',
            metaDescription: 'The latest events and seminars from Level 22 Chambers a leading administrative and commercial set in Sydney, Australia.',
            slug: 'events'
        }} backgroundImage={''}>
            <SEO title={'Events and Seminars'} description={'Events and seminars page for Level 22 Chambers'}/>
            <Container maxWidth={'lg'} className={classes.bodyWrapper}>
                <section className={classes.bodyContainer}>
                    <Typography variant={'h1'} component={'h1'}>
                        Events + Seminars
                    </Typography>
                    <div className={classes.articleListContainer}>
                        {
                            eventList.length > 0 ? renderEventList(eventList) :
                            <Typography align={'center'} variant={'h4'} component={'p'}>
                                More events coming soon!
                            </Typography>
                        }
                    </div>
                    <Typography style={{fontSize: '2.4rem', fontWeight: 500, margin: '2rem 0'}} align={'center'}
                                variant={'h2'} component={'h2'}>
                        Past Events + Seminars
                    </Typography>
                    <div className={classes.articleListContainer}>
                        {pastEventList.length > 0 ? renderEventList(pastEventList) :
                         <Typography align={'center'} variant={'h4'} component={'p'}>Coming soon!</Typography>}
                    </div>
                </section>
            </Container>
        </Layout>
    )
}

export default EventsPage

